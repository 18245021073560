"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import useAuth from "@/Hooks/useAuth";

const navItems = [
  { label: "Home", href: "/" },
  { label: "Services", href: "/services" },
  { label: "About Us", href: "/about_us" },
  { label: "Contact Us", href: "/contact_us" },
  { label: "Rent", href: "/rent" },
  { label: "FAQ", href: "#home_faq" },
  { label: "Blog", href: "#blogs" },
];

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const pathName = usePathname();
  const router = useRouter();

  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);
  const [isClient, setIsClient] = useState(false);

  const { userData, loading, handleLogOut } = useAuth();

  useEffect(() => {
    setIsClient(true);
  }, []);

  // Handle menu item click
  const handleMenuItemClick = (event, item) => {
    if (item.href.startsWith("#")) {
      // Prevent default action
      event.preventDefault();

      // Store the target section in local storage
      localStorage.setItem("targetSection", item.href);

      // Check if currently on home page
      if (pathName === "/") {
        const targetElement = document.querySelector(item.href);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
          localStorage.removeItem("targetSection");
        }
      } else {
        // Navigate to home
        router.push("/");
      }
    }
    setMobileMenuOpen(false);
  };

  //  to scroll to target section when pathname changes
  useEffect(() => {
    const targetSection = localStorage.getItem("targetSection");

    if (targetSection) {
      const targetElement = document?.querySelector(targetSection);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
      localStorage.removeItem("targetSection");
    }
  }, [pathName]);

  // Testing purpose

  // const handleBook = () => {
  //   axios
  //     .get(
  //       `https://appointment.abroadportals.com/api/v1/get-plugin-url/?api_key=B631ECAADFC343BFA7AA0EE7FD8EF2EA256871DB99490C6E816A870017531DC0&domain=rumanails.com&email=${
  //         userData ? `${userData.email}` : ""
  //       }`
  //     )
  //     .then((res) => {
  //       console.log(res?.data.url);
  //       router.push(res?.data?.url);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <nav className="bg-white border-b">
      <div className="flex items-center justify-between max-w-[120rem] mx-auto p-3">
        <Link
          href="/"
          className="w-44 md:w-40 xl:w-auto active:scale-95 transition-all  duration-300"
        >
          <Image
            src="https://res.cloudinary.com/dydv6uxzo/image/upload/v1730352507/RumaNails/Logo/NavLogo_upscaled_u6zwp4.webp"
            width={250}
            height={200}
            priority={true}
            quality={100}
            className="object-contain w-auto h-auto"
            alt="Logo"
          />
        </Link>

        <div className="flex  items-center md:order-2 space-x-1 md:space-x-2 rtl:space-x-reverse xl:w-40 justify-end">
          <section className="flex items-center gap-3 justify-end">
            {/* <div>
              <button
                onClick={handleBook}
                className="btn btn-sm lg:btn-md border-none text-white bg-primaryColor hover:bg-primaryColor"
              >
                Book Now
              </button>
            </div> */}
            {isClient && userData?.email && !loading ? (
              <section className=" lg:w-44 flex justify-end">
                <div className="dropdown dropdown-end">
                  <div
                    tabIndex={0}
                    role="button"
                    className="p-1 text-primaryColor hover:text-primaryColorDeep transition-all duration-300 rounded-full avatar"
                  >
                    <div className=" rounded-full border  ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        viewBox="0 0 32 32"
                      >
                        <path
                          fill="none"
                          d="M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0M20.5 12.5A4.5 4.5 0 1 1 16 8a4.5 4.5 0 0 1 4.5 4.5"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M26.749 24.93A13.99 13.99 0 1 0 2 16a13.9 13.9 0 0 0 3.251 8.93l-.02.017c.07.084.15.156.222.239c.09.103.187.2.28.3q.418.457.87.87q.14.124.28.242q.48.415.99.782c.044.03.084.069.128.1v-.012a13.9 13.9 0 0 0 16 0v.012c.044-.031.083-.07.128-.1q.51-.368.99-.782q.14-.119.28-.242q.451-.413.87-.87c.093-.1.189-.197.28-.3c.071-.083.152-.155.222-.24ZM16 8a4.5 4.5 0 1 1-4.5 4.5A4.5 4.5 0 0 1 16 8M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <ul
                    tabIndex={0}
                    className="menu menu-md dropdown-content bg-gray-100 rounded-box z-50 w-52 mt-3 p-2 shadow"
                  >
                    <li>
                      <Link href={"/user_dashboard/dashboard"}>
                        {userData?.name}
                      </Link>
                    </li>
                    {/* <li>
                      <Link href={"/user_dashboard/dashboard"}>Dashboard</Link>
                    </li> */}
                    <li>
                      <button
                        onClick={handleLogOut}
                        className="text-red-600 font-semibold"
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </section>
            ) : (
              <>
                <div>
                  <Link
                    href="/sign_in"
                    className="btn btn-sm lg:btn-md border-none text-white bg-primaryColor hover:bg-primaryColor"
                  >
                    Get Started
                  </Link>
                </div>
              </>
            )}
          </section>

          {/* Mobile menu toggle */}
          <button
            onClick={toggleMobileMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="mega-menu"
            aria-expanded={isMobileMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            {isMobileMenuOpen ? (
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Desktop Menu */}
        <div
          id="desktop-menu"
          className="hidden md:flex items-center justify-between w-full md:w-auto "
        >
          <ul className="flex flex-row font-medium lg:text-lg  lg:space-x-6 rtl:space-x-reverse">
            {navItems.map((item, index) => (
              <li key={index}>
                <Link
                  href={item.href}
                  className={`block py-2 px-3 ${
                    pathName === item.href
                      ? "text-primaryColor font-semibold"
                      : "text-blackCustom font-medium hover:text-primaryColor transition-all"
                  }`}
                  onClick={(event) => handleMenuItemClick(event, item)}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        id="mobile-menu"
        className={`${
          isMobileMenuOpen ? "open" : ""
        } w-full border-t border-dotted`}
      >
        <ul className="flex flex-col font-medium text-lg">
          {navItems.map((item, index) => (
            <li key={index} className="border-b border-dotted">
              <Link
                href={item.href}
                className={`block py-2 px-3 ${
                  pathName === item.href
                    ? "text-primaryColor font-semibold"
                    : "text-blackCustom font-medium hover:text-primaryColor transition-all"
                }`}
                onClick={(event) => handleMenuItemClick(event, item)}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
